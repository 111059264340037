import {useConfig} from "../../../../Services/ConfigContext";
import React from 'react';

const EducationItem = ({data}) => {

    const config = useConfig();
    const backgroundImage = JSON.parse(data.background)[0];

    return (<div
            className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_even sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_image_position_top sc_blogger_title_tag_h5 post-188 product type-product status-publish has-post-thumbnail product_cat-accessories product_tag-item product_tag-store product_tag-trend first instock taxable shipping-taxable purchasable product-type-variable">
            <div className="sc_blogger_item_body">
                <div
                    className="post_featured with_thumb hover_shop sc_item_featured sc_blogger_item_featured post_featured_bg mb-2"
                    data-ratio="1:1">
                    <div className="featured_bg_wrapper">
                        <div className="featured_bg"
                             style={{backgroundImage: 'url(' + config.imagesPath + backgroundImage+')'}}></div>
                    </div>
                    <div className="icons">
                        <a href={"/egitimler/"+data.pageUrl} className="button shop-link"><i className={"fa fa-arrow-right"}></i>
                        </a>
                        <a href={data.linkUrl} ><i className={"fa fa-shopping-cart"}></i> </a>
                    </div>
                </div>
                <div className="sc_blogger_item_content entry-content mt-0">
                    <small>{data.categoryTitle}</small>
                    <h5
                    className="sc_blogger_item_title entry-title mt-0"><a
                    href={"/egitimler/"+data.pageUrl}>
                    {data.title}</a></h5>
                    <div className="sc_blogger_item_price sc_item_price justify-content-between d-flex">
                        <span
                            className="woocommerce-Price-amount amount">{data.price} ₺</span>
                        <a href={"/egitimler/"+data.pageUrl} className={"btn btn-outline-danger mx-2"}>Hemen İncele</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EducationItem