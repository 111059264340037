import React, {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import BaseService from "../../../Services/BaseService";
import ManagementProductsService from "../../../Services/ManagementProductsService";
import {ReactSortable} from "react-sortablejs";
import {useConfig} from "../../../Services/ConfigContext";
import ProductDetail from "../../../Pages/Products/ProductDetail/ProductDetail";
import TextEditor from "../../Layout/Utils/TextEditor/TextEditor";

const ProductEdit = (props) => {

    const divWidthRef = useRef();
    const [zoomRatio, setZoomRatio] = useState();
    const desktopContainerWidth = 1200;

    const [photos, setPhotos] = useState([]);
    const config = useConfig();
    const [sortActive, setSortActive] = useState(false);


    const [form, setForm] = useState({
        id: null,
        title: '',
        description: '',
        information: '',
        categoryTitle: '',
        linkUrl: '',
        background: '',
        price: ''
    })
    const handleForm = (key, value) => {
        console.log(value);
        setForm({...form, [key]: value});
    }

    const handleFileChange = (event, index) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            var baseService = new BaseService();
            baseService.uploadImage(selectedFile).then(response => {
                setSortActive(false);
                var currentPhotos = photos;
                currentPhotos[index] = response.data;
                setPhotos(currentPhotos);
                setTimeout(() => {
                    setSortActive(true);
                }, 5)
            })
        }
    };

    const save = () => {
        form.background = JSON.stringify(photos);
        ManagementProductsService.saveProduct(form).then(response => {
            toast.success('Ürün kaydedildi');
            props.onClose()
        })
    }
    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / desktopContainerWidth);
        }
    }

    const addPhoto = () => {
        setSortActive(false);
        var currentPhotos = photos;
        currentPhotos.push("");
        setPhotos(currentPhotos);
        setTimeout(() => {
            setSortActive(true);
        }, 5)
    }

    const removePhoto = (index) => {
        setSortActive(false);
        var currentPhotos = photos;
        currentPhotos.splice(index,1);
        setPhotos(currentPhotos);
        setTimeout(() => {
            setSortActive(true);
        }, 5)
    }



    useEffect(() => {
        if (props.productId !== -1) {
            ManagementProductsService.getProduct(props.productId).then(response => {
                setForm({...form, ...response.data});
                if (response.data.background !== null) {
                    setPhotos(JSON.parse(response.data.background));
                }
                setSortActive(true);
                handleResize();
            });
        }
    }, [props.productId]);


    useEffect(() => {
        handleResize();
    }, [divWidthRef, photos])

    return (
        <div className="row">
            <ul className="acn-theme-breadcrumb list-unstyled">
                <li><a href="/management">Yönetim Paneli</a></li>
                <li><a href="/management/products">Ürün Yönetimi</a></li>
                <li><span>{props.product !== -1 ? "Ürün Düzenle" : "Ürün Ekle"}</span></li>
            </ul>

            <div className={"row"} ref={divWidthRef}></div>


            <div className={"col-12 d-flex mt-2 mb-2"}>
                <label className={"form-label col-2 text-left my-auto"}>Önizleme</label>

            </div>
            <div className={"col-12 d-flex mt-2 mb-4"}>
                <div className={"container"} style={{zoom: zoomRatio}}>
                    <div className={"row my-4"}>
                        <ProductDetail data={form} photos={photos}></ProductDetail>

                    </div>
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Ürün Başlığı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.title}
                           onChange={(e) => handleForm("title", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Ürün Kategori Başlığı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.categoryTitle}
                           onChange={(e) => handleForm("categoryTitle", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Satın Alma Bağlantısı</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.linkUrl}
                           onChange={(e) => handleForm("linkUrl", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Fiyat</label>
                <div className={"col-9 m-auto"}>
                    <input className="form-control" value={form.price}
                           onChange={(e) => handleForm("price", e.target.value)}/>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Ön Bilgi</label>
                <div className={"col-9 m-auto"}>
                   <TextEditor onEditorChange={(e) => handleForm("information",e)} initialValue={form.information} height={400}></TextEditor>
                </div>
            </div>
            <div className={"col-md-12 d-flex mt-2"}>
                <label className={"form-label col-3 text-left my-auto"}>Ürün Detayları</label>
                <div className={"col-9 m-auto"}>
                   <TextEditor onEditorChange={(e) => handleForm("description",e)} initialValue={form.description} height={400}></TextEditor>
                </div>
            </div>

            <hr className={"my-4"}/>
            <div className={"col-md-12 d-flex"}>
                <label className={"form-label col-3 text-left my-auto mb-2"}>Fotoğraflar</label>
            </div>

            {sortActive &&
                <ReactSortable list={photos} setList={setPhotos} className={"d-flex row"}>
                    {
                        photos.map((d, index) => {
                            return (
                                <div key={index} className={"col-md-6 d-flex mt-2"} style={{height : 150}}>
                                    <div className={"col-md-3"}>
                                        {d.length > 1 &&
                                            <img style={{height : 150, objectFit : "contain"}} className={"my-auto w-100"} src={config.imagesPath + d}/>
                                        }
                                    </div>
                                    <div className={"col-6 my-auto mx-2"}>
                                        <label className={"form-label text-left my-auto"}>Fotoğraf
                                            Ekle/Değiştir</label>
                                        <input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            onChange={(e) => handleFileChange(e, index)}
                                        />
                                            <a className={"btn btn-outline-danger mt-2"} onClick={() => removePhoto(index)}>Fotoğrafı Sil</a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </ReactSortable>
            }


            <div className={"col-md-12 text-right mt-4"}>
                <a onClick={() => addPhoto()} className="btn btn-secondary"><i
                    className={"fa fa-plus mx-1"}></i> Fotoğraf
                    Ekle</a>
                <a onClick={() => props.onClose()} className="btn btn-secondary mx-2"><i
                    className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                <a onClick={() => save()} className="btn btn-secondary"><i className={"fa fa-save mx-1"}></i> Kaydet</a>
            </div>
        </div>
    )
}

export default ProductEdit