import React, {useEffect, useRef} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import "videojs-youtube";

const VideoPlayer = ({options}) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        console.log(options);
        if (!playerRef.current) {
            const videoElement = document.createElement("video-js");

            videoElement.classList.add("vjs-big-play-centered");
            videoRef.current.appendChild(videoElement);

            const player = (playerRef.current = videojs(videoElement, options));
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
            player.playsInline = true;
        }
    }, [options, videoRef]);

    useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player className={"w-100"}>
            <div className={"video-container p-0"} ref={videoRef} />
        </div>
    );
};

export default VideoPlayer;
