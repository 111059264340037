import {useEffect, useState} from "react";
import {useConfig} from "../../../Services/ConfigContext";
import "./ElementPhotoContent.css"
import VideoPlayer from "../../Layout/Utils/VideoPlayer/VideoPlayer";
import React from 'react';

const ElementPhotoContent = (props) => {

    const config = useConfig();
    const [videoJsOptions, setVideoJsOptions] = useState(null);

    const [data, setData] = useState({
        imagePosition: 'left', //left, right
        imageColsDesktop: 6, // 2-8
        imageColsMobile: 12, // 6-12
        image: '',
        content: '',
        imageType : 'image' //image,video
    });

    useEffect(() => {
        if (props) {
            setData({...data, ...props.data});
            if(props.data?.imageType === "video"){
                setVideoJsOptions({
                    autoplay: false,
                    controls: true,
                    techOrder: ['youtube'],
                    sources: [{
                        src: props.data.image,
                        type: 'video/youtube'
                    }],
                    aspectRatio : "9:16"
                });
                console.log(props);
            }
        }
    }, [props]);

    return (
        <div
            className={"mt-20 w-100 d-flex flex-direction-"+data.imagePosition}>
            <div className={"col-md-" + data.imageColsDesktop + " col-xs-" + data.imageColsMobile}
                 style={{display: data.imagePosition === "none" ? "none" : "flex"}}>
                {data.imageType === "image" &&
                    <img className={"photo-content-img"} alt={"Serap Koçak"}
                     src={data.image.length > 100 ? data.image : config.imagesPath + data.image}/>
                }
                {(videoJsOptions && data.imageType === "video") &&
                    <VideoPlayer className={"m-auto"} options={videoJsOptions}/>
                }
            </div>
            <div
                className={"d-flex col-md-" + (data.imagePosition === "none" ? 12 : (12 - data.imageColsDesktop) + " col-xs-" + (data.imageColsMobile === 12 ? 12 : 12 - data.imageColsMobile))}>
                <div className={"w-100 photo-content-content my-auto"} dangerouslySetInnerHTML={{__html: data.content}}></div>
            </div>
        </div>
    )
}

export default ElementPhotoContent