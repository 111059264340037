import "./PageEditor.css"
import React, {useEffect, useRef, useState} from "react";
import Select from "react-select";
import EditorPhotoContent from "../ElementEditor/EditorPhotoContent/EditorPhotoContent";
import EditorFaq from "../ElementEditor/EditorFaq/EditorFaq";
import EditorFeature from "../ElementEditor/EditorFeature/EditorFeature";
import {useNavigate, useParams} from "react-router-dom";
import toast from "react-hot-toast";
import swal from "sweetalert2";
import EditorSidebarContent from "../ElementEditor/EditorSidebarContent/EditorSidebarContent";
import EditorBoxView from "../ElementEditor/EditorBoxView/EditorBoxView";
import ManagementPageDetailService from "../../../../Services/ManagementPageDetailService";
import ManagementBlogDetailService from "../../../../Services/ManagementBlogDetailService";
import BaseService from "../../../../Services/BaseService";
import PageContainer from "../../../../Pages/PageContainer/PageContainer";

const PageEditor = () => {

    const [zoomRatio, setZoomRatio] = useState(false);
    const divWidthRef = useRef();
    const containerWidth = 1920;

    const pageId = useParams()["*"];

    const editorType = window.location.pathname.startsWith("/management/blog-editor") ? "blog" : "pages";
    const editorString = editorType === "blog" ? "Blog" : "Sayfa"

    const navigate = useNavigate();

    const [form, setForm] = useState({
        id: null,
        url: '',
        title: '',
        bannerImage: '',
        categoryTitle: '',
        metaDescription: '',
        content: [],
        creator: '',
        date: '',
    })

    const handleResize = () => {
        if (divWidthRef.current) {
            setZoomRatio(divWidthRef.current.clientWidth / containerWidth);
        }
    }

    useEffect(() => {
        if (pageId !== "new") {
            (editorType === "blog" ? ManagementBlogDetailService.getBlogDetail(pageId) : ManagementPageDetailService.getPageDetail(pageId)).then(response => {
                var modifiedResponse = response.data;
                modifiedResponse.content = JSON.parse(modifiedResponse.content);
                setForm(modifiedResponse);
            })
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    }, []);

    const elements = [
        {
            label: 'Fotoğraf/Video ve İçerik',
            value: 'photo-content',
            editor: <EditorPhotoContent></EditorPhotoContent>
        },
        {
            label: 'Öne Çıkanlar',
            value: 'Features',
            editor: <EditorFeature></EditorFeature>
        },
        {
            label: 'SSS',
            value: 'faq',
            editor: <EditorFaq></EditorFaq>
        },
        {
            label: 'Kutu Görünümü',
            value: 'box-view',
            editor: <EditorBoxView></EditorBoxView>
        },
        {
            label: 'Sidebar ve İçerik',
            value: 'sidebar-content',
            editor: <EditorSidebarContent></EditorSidebarContent>
        },
    ];

    const [selectedElement, setSelectedElement] = useState(elements[0]);

    const [activeElementEditor, setActiveElementEditor] = useState(null);

    const activeElementEditorType = useRef(null);
    const updatingElementIndex = useRef(null);

    const handleForm = (key, value) => {
        setForm({...form, [key]: value});
    }
    const handleFileChange = (key, event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            let baseService = new BaseService();
            baseService.uploadImage(selectedFile).then(response => {
                setForm({...form, [key]: response.data})
            })
        }
    }

    const addElement = (el) => {
        updatingElementIndex.current = null;
        activeElementEditorType.current = el.value;
        const editorWithSave = React.cloneElement(el.editor, {
            saveElement: onElementSave,
            closeElementEditor: onEditorClose
        });
        setActiveElementEditor(editorWithSave);
    }

    const onElementSave = (data) => {
        let currentForm = form;
        if (updatingElementIndex.current !== null) {
            currentForm.content[updatingElementIndex.current].data = data;
        } else {
            currentForm.content.push({type: activeElementEditorType.current, data: data});
        }
        setForm(currentForm);
        onEditorClose();
    }

    const onEditorClose = () => {
        updatingElementIndex.current = null;
        setActiveElementEditor(null);
    }

    const savePage = () => {
        let modifiedForm = form;
        modifiedForm.content = JSON.stringify(form.content);
        (editorType === "blog" ? ManagementBlogDetailService.saveBlogDetail(modifiedForm) : ManagementPageDetailService.savePageDetail(modifiedForm)).then(response => {
            toast.success(editorString + " kaydedildi");
            navigate("../" + editorType);
        }).catch(e => {
            toast.error(e?.response?.data);
        })
    }

    const sortPage = (sortedData) => {
        setForm({
            ...form, content: sortedData.map(sd => {
                return {type: sd.type, data: sd.data}
            })
        })
    }

    const editElement = (index) => {
        const editingContent = form.content[index];
        const el = elements.find(e => e.value === editingContent.type);
        updatingElementIndex.current = index;
        activeElementEditorType.current = el.value;
        const editorWithSave = React.cloneElement(el.editor, {
            data: editingContent.data,
            saveElement: onElementSave,
            closeElementEditor: onEditorClose
        });
        setActiveElementEditor(editorWithSave);
    }

    const removeElement = (index) => {
        swal.fire({
            title: "İşleme devam et",
            html: "Seçili bölüm silinecek, onaylıyor musunuz?",
            icon: "warning",
            confirmButtonText: 'Onaylıyorum',
            showCancelButton: true,
            cancelButtonText: 'Vazgeç',
            confirmButtonColor: '#E6B088',
        }).then(function (isConfirm) {
            if (isConfirm.value) {
                form.content.splice(index, 1);
                setForm({...form, content: form.content})
                toast.success('Bölüm silindi');
            }
        })
    }

    return (
        <div className={"row p-0"}>
            <ul className="acn-theme-breadcrumb list-unstyled">
                <li><a href="/management">Yönetim Paneli</a></li>
                <li><a href={"/management/" + editorType}>{editorString} Yönetimi</a></li>
                <li><span>{editorString} Oluşturma Sihirbazı</span></li>
            </ul>

            <div className={"col-md-12 d-flex mt-2 "}>
                <label className={"form-label col-3 text-left my-auto"}>{editorString} Başlığı</label>
                <div className="col-9 m-auto">
                    <input className="form-control" value={form.title}
                           onChange={(e) => handleForm("title", e.target.value)}/>
                </div>
            </div>


            <div className={"col-md-12 d-flex mt-2 "}>
                <label className={"form-label col-3 text-left my-auto"}>{editorString} Adresi</label>
                <div className="col-9 m-auto">
                    <input className="form-control" value={form.url}
                           onChange={(e) => handleForm("url", e.target.value)}/>
                </div>
            </div>

            {
                editorType === "blog" &&
                <>
                    <div className={"col-md-12 d-flex mt-2 "}>
                        <label className={"form-label col-3 text-left my-auto"}>Kategori</label>
                        <div className="col-9 m-auto">
                            <input className="form-control" value={form?.categoryTitle}
                                   onChange={(e) => handleForm("categoryTitle", e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-md-12 d-flex mt-2 "}>
                        <label className={"form-label col-3 text-left my-auto"}>Oluşturan</label>
                        <div className="col-9 m-auto">
                            <input className="form-control" value={form?.creator}
                                   onChange={(e) => handleForm("creator", e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-md-12 d-flex mt-2 "}>
                        <label className={"form-label col-3 text-left my-auto"}>Tarih</label>
                        <div className="col-9 m-auto">
                            <input className="form-control" value={form?.date}
                                   onChange={(e) => handleForm("date", e.target.value)}/>
                        </div>
                    </div>
                    <div className={"col-md-12 d-flex mt-2 "}>
                        <label className={"form-label col-3 text-left my-auto"}>Kapak Fotoğrafı</label>
                        <div className={"col-9 m-auto"}>
                            <input
                                className="form-control"
                                type="file"
                                onChange={(e) => handleFileChange("bannerImage", e)}
                            />
                        </div>
                    </div>
                </>

            }

            <div className={"col-md-12 d-flex mt-2 d-none"}>
                <label className={"form-label col-3 text-left my-auto"}>SEO Meta Title</label>
                <div className="col-9 m-auto">
                    <input className="form-control" value={form.metaTitle}
                           onChange={(e) => handleForm("metaTitle", e.target.value)}/>
                </div>
            </div>

            <div className={"col-md-12 d-flex mt-2 "}>
                <label className={"form-label col-3 text-left my-auto"}>SEO Meta Description</label>
                <div className="col-9 m-auto">
                    <input className="form-control" value={form.metaDescription} maxLength={160}
                           onChange={(e) => handleForm("metaDescription", e.target.value)}/>
                </div>
            </div>

            <hr className={"mt-20"}/>
            <div className={"col-md-12 d-flex mt-2"} ref={divWidthRef}>
                <label className={"form-label col-3 text-left my-auto"}>Önizleme</label>
            </div>
            <div className={"col-md-12 d-flex mt-2 "} style={{zoom: zoomRatio}}>
                <div className={"page-wrapper w-100"}>
                    <PageContainer onSort={sortPage} onRemoveElement={removeElement} onEditElement={editElement}
                                   page={form}></PageContainer>
                </div>
            </div>


            <hr className={"mt-20"}/>
            <div className={"col-md-12 d-flex justify-content-lg-end mb-20"}>
                <div className={"col-md-1 d-flex mt-2 text-right"}>
                    <label className={"form-label col-12 text-left my-auto"}>Element</label>
                </div>
                <div className={"col-md-3 mt-2 mx-3"}>
                    <Select isSearchable={false}
                            isMulti={false}
                            defaultValue={elements[0]}
                            onChange={(selectedOption) => setSelectedElement(selectedOption)}
                            options={elements}
                            classNamePrefix="form-control-select"/>
                </div>
                <div className={"col-md-1 mt-2 d-flex"}>
                    <a onClick={() => addElement(selectedElement)}
                       className={"btn btn-secondary my-auto px-4"}>Ekle</a>
                </div>
            </div>
            <hr/>

            <div className={"col-md-12 text-right"}>
                <a className="btn btn-secondary mx-2" onClick={() => navigate('../' + editorType)}><i
                    className={"fa fa-cancel mx-1"}></i> Vazgeç</a>
                <a className="btn btn-secondary" onClick={() => savePage()}><i className={"fa fa-save mx-1"}></i> Kaydet</a>
            </div>

            {
                activeElementEditor !== null &&
                <div className={"modal"} id="staticBackdrop">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Element Düzenle</h5>
                                <button type="button" className="btn-close"
                                        onClick={onEditorClose}></button>
                            </div>
                            <div className="modal-body">
                                {activeElementEditor}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PageEditor