import React, {useEffect, useState} from "react";
import ElementPhotoContent from "../PageElements/ElementPhotoContent/ElementPhotoContent";
import ElementFaq from "../PageElements/ElementFaq/ElementFaq";
import ElementFeature from "../PageElements/ElementFeature/ElementFeature";
import {ReactSortable} from "react-sortablejs";
import {useConfig} from "../../Services/ConfigContext";
import "./PageContainer.css"
import ElementSidebarContent from "../PageElements/ElementSidebarContent/ElementSidebarContent";
import ElementBoxView from "../PageElements/ElementBoxView/ElementBoxView";

const PageContainer = (props) => {

    const elements = [
        {
            value: 'photo-content',
            element: <ElementPhotoContent></ElementPhotoContent>,
        },
        {
            value: 'Features',
            element: <ElementFeature></ElementFeature>
        },
        {
            value: 'faq',
            element: <ElementFaq></ElementFaq>
        },
        {
            value: 'box-view',
            element: <ElementBoxView></ElementBoxView>
        },
        {
            value: 'offer-form',
        },
        {
            value: 'sidebar-content',
            element: <ElementSidebarContent></ElementSidebarContent>
        },
    ];

    const [page, setPage] = useState({
        url: '',
        title: '',
        content: []
    })

    const config = useConfig();

    useEffect(() => {
        if (props.hasOwnProperty('page')) {
            setPage(props.page);
        }
    }, [props]);

    const [activeHoverIndex,setActiveHoverIndex] = useState(null);

    const prepareElement = (data) => {
        return React.cloneElement(elements.find(el => el.value === data.type).element, {
            data: data.data
        });
    }

    return (
        <div className={"page-wrapper page-container my-5"}>
            {page.content.length > 0 &&
                <div className={"container"}>
                    {props.hasOwnProperty('onSort') &&
                        <ReactSortable list={page.content} setList={props?.onSort} disabled={!(props?.onSort)}
                                       tag={"div"}>
                            {page.content.map((e, i) => {
                                return (
                                    <div key={i} className={"mb-5 position-relative"} onDrag={() => setActiveHoverIndex(i)} onMouseEnter={() => setActiveHoverIndex(i)} onMouseLeave={() => setActiveHoverIndex(null)}>
                                        <div style={{display : (activeHoverIndex === i ? "flex" : "none")}} className={"page-section-operations w-100 h-100"}>
                                            <div className={"d-inline-block m-auto"}>
                                            <span className={"mx-2"}>
                                                <i className={"fa fa-arrows"}></i> Taşımak için sürükleyin
                                            </span>
                                                <br/>
                                                <a className={"btn btn-outline-success mx-2"}
                                                   onClick={() => props?.onEditElement(i)}>
                                                    <i className={"fa fa-pencil"}></i> Düzenle
                                                </a>
                                                <a className={"btn btn-outline-danger mx-2"}
                                                   onClick={() => props?.onRemoveElement(i)}>
                                                    <i className={"fa fa-remove"}></i> Sil
                                                </a>
                                            </div>
                                        </div>
                                        {
                                            prepareElement(e)
                                        }
                                    </div>
                                )
                            })}
                        </ReactSortable>
                    }
                    {!props.hasOwnProperty('onSort') &&
                        page.content.map((e, i) => {
                            return (
                                <div key={i} className={"mb-5 position-relative"}>
                                    {prepareElement(e)}
                                </div>
                            );
                        })
                    }
                </div>
            }
        </div>
    )
}

export default PageContainer;